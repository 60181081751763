/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

import { BASE_ENDPOINT } from '../constant';

export const getSocialVideoChannels = async (): Promise<any> => {
  const baseUrl = `${BASE_ENDPOINT}/social_video_channels`;

  try {
    const config = {
      method: 'get',
      url: baseUrl,
    };
    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export const getSocialVideos = async ({
  channelId,
  pageSize = 6,
  page = 1,
}: {
  channelId: string;
  pageSize?: number;
  page?: number;
}): Promise<any> => {
  const baseUrl = `${BASE_ENDPOINT}/social_video`;
  const url = `${baseUrl}?channel=${channelId}&per_page=${pageSize}&page=${page}`;

  try {
    const config = {
      method: 'get',
      url: url,
    };
    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};
