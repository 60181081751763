import SocialVideoCard from '@apps/components/SocialVideoCard';
import { ISocialVideo } from '@apps/types';
import { parseImageUrlString } from '@apps/utils/image-string-parser';

import { TiktokCardFlex, TiktokCardGrid } from './styles';

export type TiktokCardGroupProps = {
  data: ISocialVideo[];
  loading?: boolean;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const TiktokCardGroup: React.FC<TiktokCardGroupProps> = (props) => {
  const { data, loading = false } = props;

  // ======================= VIEWS
  if (!data?.length) return null;
  return (
    <TiktokCardFlex>
      {data.map((video, index) => {
        const thumbnail = parseImageUrlString(video.feed_thumb);
        return (
          <SocialVideoCard
            key={`${video.feed_title}-${index}`}
            title={video.feed_title}
            thumbnail={thumbnail?.standard?.url || ''}
            href={video.feed_url}
            views={video.feed_view_count}
            loading={loading}
          />
        );
      })}
    </TiktokCardFlex>
  );
  return (
    <TiktokCardGrid>
      {data.map((video, index) => {
        const thumbnail = parseImageUrlString(video.feed_thumb);
        return (
          <SocialVideoCard
            key={`${video.feed_title}-${index}`}
            title={video.feed_title}
            thumbnail={thumbnail?.standard?.url || ''}
            href={video.feed_url}
            views={video.feed_view_count}
          />
        );
      })}
    </TiktokCardGrid>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default TiktokCardGroup;
