import { priceFormat } from '@apps/utils/format-price';
import placeholderImage from '@stack/assets/images/placeholder-image-tiktok.jpg';
import styled from 'styled-components';

import StyledImage from '../Image';

export type SocialVideoCardProps = {
  thumbnail: string;
  href: string;
  title: string;
  views: string | number;
  loading?: boolean;
};

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.54) 100%
  );

  @media only screen and (max-width: 768px) {
    padding: 8px 10px;
  }
`;

const ViewsLabel = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: white;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const Card = styled.div`
  height: 330px;
  aspect-ratio: 3/4;

  @media only screen and (min-width: 768px) {
    height: 390px;
  }
`;

const TiktokSkeletonWrapper = styled.div`
  min-width: 232px;
  min-height: 412px;

  @media only screen and (max-width: 768px) {
    min-width: 136px;
    min-height: 241px;
  }
`;

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const SocialVideoCard: React.FC<SocialVideoCardProps> = (props) => {
  const { thumbnail, href, title, views, loading } = props;

  if (loading)
    return (
      <div>
        <TiktokSkeletonWrapper
          style={{
            backgroundColor: 'black',
            position: 'relative',
          }}
          className="skeleton"
        ></TiktokSkeletonWrapper>
      </div>
    );

  // ======================= VIEWS
  return (
    <div>
      <a
        href={href}
        target="_blank"
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
            }}
          >
            <Card>
              <StyledImage
                src={thumbnail}
                alt={title}
                width={300}
                height={400}
                imageStyle={{
                  height: '100%',
                }}
                fallbackSrc={placeholderImage.src}
              />
            </Card>
            <Overlay>
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.3335V14.6668M12.6667 8.00016L1 14.6668M12.6667 8.00016L1 1.3335"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <ViewsLabel>{priceFormat({ price: views })}</ViewsLabel>
            </Overlay>
          </div>

          {/* title */}
          <p
            style={{
              fontSize: 14,
              fontWeight: 400,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              overflow: 'hidden',
            }}
          >
            {title}
          </p>
        </div>
      </a>
    </div>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default SocialVideoCard;
