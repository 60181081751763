import { IImageData } from '@apps/types';

export function parseImageUrlString(imageUrlString: string): IImageData | null {
  try {
    // Parse the JSON string into an object
    const imageData: IImageData = JSON.parse(imageUrlString);

    // Return the parsed object
    return imageData;
  } catch (error) {
    // Handle any parsing errors
    console.error('Error parsing image URL string:', error);
    return null;
  }
}
