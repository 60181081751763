import { useEffect, useState } from 'react';

export const useResponsiveItems = () => {
  const [itemsCount, setItemsCount] = useState(6);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setItemsCount(2);
      } else if (window.innerWidth <= 900) {
        setItemsCount(4);
      } else {
        setItemsCount(6);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial call
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return itemsCount;
};
