import VideoCard from '@apps/components/VideoCard';
import { ISocialVideo } from '@apps/types';
import { parseImageUrlString } from '@apps/utils/image-string-parser';
import { useResponsiveItems } from '@apps/utils/responsive';

import { YoutubeCardGrid } from './styles';

export type YoutubeCardGroupProps = {
  data: ISocialVideo[];
  loading?: boolean;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const YoutubeCardGroup: React.FC<YoutubeCardGroupProps> = (props) => {
  const { data, loading } = props;
  const size = useResponsiveItems();

  // ======================= VIEWS
  if (!data?.length) return null;
  const displayItem = data.slice(0, size);
  return (
    <YoutubeCardGrid>
      {displayItem.map((video, index) => {
        const thumbnail = parseImageUrlString(video.feed_thumb);
        return (
          <VideoCard
            key={`${video.feed_channel_title}-${index}`}
            title={video.feed_title}
            thumbnail={thumbnail?.high?.url || ''}
            avatar={video.feed_channel_avatar}
            channel={video.feed_channel_title}
            date={video.feed_date}
            link={video.feed_url}
            loading={loading}
          />
        );
      })}
    </YoutubeCardGrid>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default YoutubeCardGroup;
