import styled from 'styled-components';

export const SocialVideoChannelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media only screen and (max-width: 768px) {
    gap: 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media only screen and (max-width: 768px) {
    gap: 20px;
  }
`;

export const SocialIconButtonWrapper = styled.div<{
  $active?: boolean;
}>`
  border: 2px solid;
  border-color: ${(props) => (props.$active ? '#31acbf' : '#edeced')};
  cursor: pointer;
  width: 52px;
  height: 52px;
  border-radius: 50px;
  :hover {
    border-color: #31acbf;
  }
`;

export const YoutubeCardGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

  @media (min-width: 901px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TiktokCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;

  @media (max-width: 568px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const TiktokCardFlex = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  scrollbar-width: none;
  gap: 20px;
`;
