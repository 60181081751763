/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ISocialVideo, ISocialVideoChannel } from '@apps/types';
import AllVideoIcon from '@stack/assets/icons/all-video.svg';

import StyledImage from '../Image';

import {
  ContentWrapper,
  SocialIconButtonWrapper,
  SocialVideoChannelWrapper,
} from './styles';
import { TiktokCardGroup } from './TiktokCardGroup';
import YoutubeCardGroup from './YoutubeCardGroup';

export type SocialVideoChannelsProps = {
  data?: {
    channels: ISocialVideoChannel[];
    videos: {
      tiktok: ISocialVideo[];
      youtube: ISocialVideo[];
    };
  };
  loading?: boolean;
  setChannelType?: any;
};

const IconButton = ({
  src,
  selected,
  onClick,
}: {
  src: React.ReactNode;
  selected?: boolean;
  onClick?: (e: any) => void;
}) => {
  const renderIcon = () => {
    if (typeof src !== 'string') return src;
    return (
      <StyledImage
        src={src}
        alt=""
        width={52}
        height={52}
        imageStyle={{
          borderRadius: '50px',
        }}
      />
    );
  };
  return (
    <SocialIconButtonWrapper onClick={(e) => onClick?.(e)} $active={selected}>
      {renderIcon()}
    </SocialIconButtonWrapper>
  );
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const SocialVideoChannels: React.FC<SocialVideoChannelsProps | any> = (
  props
) => {
  const { data, setChannelType, loading } = props;
  const channels = data?.channels;

  // ======================= HOOKS

  // ======================= STATE
  const [channel, setChannel] = useState<string | number>('all');

  // ======================= API

  // ======================= EVENTS

  const handleChannelClick = (channel: 'all' | number) => {
    setChannel(channel);

    const selectedChannel = data?.channels.find(
      (c: any) => c.feed_channel_id === channel
    );
    const channelId = selectedChannel?.feed_channel_id;
    setChannelType(channelId);
  };

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS
  const renderSocialIcons = () => {
    return (
      <div
        style={{
          display: 'flex',
          gap: 10,
          flexDirection: 'row',
        }}
      >
        <IconButton
          src={<AllVideoIcon />}
          selected={channel === 'all'}
          onClick={() => handleChannelClick('all')}
        />
        {channels?.map((ch: any) => {
          return (
            <IconButton
              key={ch.feed_channel_id}
              src={ch.feed_channel_avatar}
              selected={channel === ch.feed_channel_id}
              onClick={(e: { preventDefault: () => void }) => {
                e.preventDefault();

                handleChannelClick(ch.feed_channel_id);
              }}
            />
          );
        })}
      </div>
    );
  };

  // ======================= VIEWS
  return (
    <SocialVideoChannelWrapper>
      {renderSocialIcons()}
      <ContentWrapper>
        {
          <TiktokCardGroup
            data={data?.videos?.tiktok || []}
            loading={loading}
          />
        }
        {
          <YoutubeCardGroup
            data={data?.videos?.youtube || []}
            loading={loading}
          />
        }
      </ContentWrapper>
    </SocialVideoChannelWrapper>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default SocialVideoChannels;
