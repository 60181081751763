import { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import StyledImage from '../Image';

export type VideoCardProps = {
  thumbnail: string;
  link: string;
  title: string;
  avatar: string;
  channel: string;
  date: string;
  loading?: boolean;
};

const VideoContentWrapper = styled.div`
  display: flex;
  padding: 20px;
  gap: 15px;

  @media only screen and (max-width: 768px) {
    padding: 10px;
    gap: 10px;
  }

  a {
    font-size: 16px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 0.3s ease-out;

    &:hover {
      color: #31acbf; /* Change to desired hover color */
    }
  }
`;

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const VideoCard: React.FC<VideoCardProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { thumbnail, link, title, avatar, channel, date, loading } = props;
  // const [loadingIframe, setLoadingIframe] = useState(true);
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const videoId = link.split('v=')[1];
  // ======================= VIEWS
  return (
    <div
      style={{
        backgroundColor: '#F7F7F7',
      }}
      className={classNames('', {})}
    >
      <div
        style={{
          textDecoration: 'none',
          color: '#1E242F',
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* <div
            style={{
              width: '100%',
              height: 'auto',
            }}
          >
            <StyledImage src={thumbnail} alt={title} width={393} height={220} />
          </div> */}
          {!isIframeVisible ? (
            <div
              className="youtube-button"
              style={{ position: 'relative', cursor: 'pointer' }}
              onClick={() => setIsIframeVisible(true)}
            >
              <StyledImage
                src={thumbnail}
                alt={title}
                width={640}
                height={360}
                imageStyle={{
                  height: 'auto',
                  width: '100%',
                  objectFit: 'cover',
                  aspectRatio: 16 / 9,
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  height: '48px',
                }}
              >
                <svg
                  viewBox="0 0 68 48"
                  width="100%"
                  height="100%"
                  fill="#212121CC"
                >
                  <path d="M66.5 7.7c-.8-2.9-2.5-5.4-5.4-6.2C55.8.1 34 0 34 0S12.2.1 6.9 1.6c-3 .7-4.6 3.2-5.4 6.1a89.6 89.6 0 0 0 0 32.5c.8 3 2.5 5.5 5.4 6.3C12.2 47.9 34 48 34 48s21.8-.1 27.1-1.6c3-.7 4.6-3.2 5.4-6.1C68 35 68 24 68 24s0-11-1.5-16.3z" />
                  <path d="M45 24L27 14v20" fill="white" />
                </svg>
              </div>
            </div>
          ) : (
            <iframe
              className="skeleton iframe-placeholder"
              width="100%"
              height="100%"
              style={{
                aspectRatio: 16 / 9,
              }}
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
              title={title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          )}
          <VideoContentWrapper>
            <div
              style={{
                width: '38px',
                minWidth: '38px',
                height: '38px',
                minHeight: '38px',
                borderRadius: '50%',
                overflow: 'hidden',
              }}
            >
              <StyledImage src={avatar} alt={channel} width={38} height={38} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              <a href={link || '/'}>{title}</a>
              <p
                style={{
                  fontSize: 13,
                  fontWeight: 400,
                }}
              >
                {channel}
              </p>
              <p
                style={{
                  fontSize: 13,
                  fontWeight: 400,
                }}
              >
                {date}
              </p>
            </div>
          </VideoContentWrapper>
        </div>
      </div>
    </div>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default VideoCard;
